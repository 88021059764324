import React from 'react';
import { SlideImage } from 'yet-another-react-lightbox-lite';

export interface ILightboxState {
  images: SlideImage[];
  imagesSet: Set<string>;
  activeIndex?: number;
  activeSrc?: string;
}

interface IAddLightboxImage {
  type: 'add';
  image: SlideImage;
}

interface IRemoveLightboxImage {
  type: 'remove';
  imageSrc: string;
}

interface IOpenLightboxImage {
  type: 'open';
  imageSrc?: string;
  imageIndex?: number;
}

interface ICloseLightboxImage {
  type: 'close';
}

export type TLightboxContextAction = IAddLightboxImage | IRemoveLightboxImage |
IOpenLightboxImage | ICloseLightboxImage;

export const lightboxStateDefault: ILightboxState = {
  imagesSet: new Set(),
  images: [],
};

export const LightboxContext = React.createContext(lightboxStateDefault);

LightboxContext.displayName = 'LightboxStateContext';

export const LightboxDispatchContext =
  React.createContext<(action: TLightboxContextAction) => void>(() => undefined);

LightboxDispatchContext.displayName = 'LightboxDispatchContext';

export function lightboxContextReducer(
  context: ILightboxState,
  action: TLightboxContextAction,
): ILightboxState {
  const { images, imagesSet, activeIndex } = context;

  switch (action.type) {
    case 'add': {
      const { image } = action;
      const { src: imageSrc } = image;

      if (imagesSet.has(imageSrc)) {
        throw Error(`Can't add image duplicate by src ${ imageSrc }`);
      }

      images.push(image);
      imagesSet.add(imageSrc);

      return {
        ...context,
      };
    }

    case 'remove': {
      const { imageSrc } = action;

      if (!imagesSet.has(imageSrc)) {
        throw Error(`Can't remove image since "${ imageSrc }" src is not found in the set`);
      }

      const indexToRemove = images.findIndex(image => image.src === imageSrc);

      if (indexToRemove === -1) {
        throw Error(`Can't remove image since "${ imageSrc }" src is not found in the list`);
      }

      if (context.activeIndex !== undefined) {
        if (indexToRemove === activeIndex) {
          context.activeIndex = undefined;
          context.activeSrc = undefined;
        } else if (context.activeIndex > indexToRemove) {
          context.activeIndex--;
        }
      }

      images.splice(indexToRemove, 1);
      imagesSet.delete(imageSrc);

      return {
        ...context,
      };
    }

    case 'open': {
      const activeIndex = action.imageIndex ??
        images.findIndex(image => image.src === action.imageSrc);

      if (activeIndex === -1) {
        throw new Error(
          `Can't open image since src "${ action.imageSrc }" is not found in the list`
        );
      }

      const activeImageSrc = action.imageSrc ?? images[activeIndex]?.src;

      if (!activeImageSrc) {
        throw new Error(
          `Image with src "${ action.imageSrc }" and ${ action.imageIndex } index ` +
          `was not found in the list"`
        );
      }

      return {
        ...context,
        activeSrc: activeImageSrc,
        activeIndex,
      };
    }

    case 'close':
      return {
        ...context,
        activeSrc: undefined,
        activeIndex: undefined,
      };
  }
}
